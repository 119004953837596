<template>

  <div>
    <div class="svg-background  typography" style="height:100vh;overflow:hidden">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="swiper-wrapper d-none d-xl-block"
              style="position:absolute; left: 5%;top: 50%;transform: translate(-5%, -50%);">

              <div style="width: 420px;">
                <div class="container" style="flex-grow: 1;text-align: start;">
                  <img alt="crowdyvest" src="/images/logo.png" style="color:white;width: 85px;height: 80px;"
                    class="logo-icon">
                  <p class="logan"
                    style="padding-top: 50px;max-width: 390px;text-align: left;font-style: normal;font-size: 50px;line-height: 112.6%;color: #fff;">
                    Simple digital address...</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-login-register text-white">

              <div class="form-login mb-0">
                <form>

                  <div class="font-size-md text-white mb-5">
                    <div class="logan-container">
                      <p class="logan-subtitle" style="margin-top: 0;font-size: 25px;color: #fff;margin-bottom: 40px;">
                        Account Email Verification</p>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <label for="code" class="sr-only">Code</label>
                    <input id="code" type="text" class="form-control font-size-lg border-0 form-control-lg bg-transparent login-input" :class="{ 'is-invalid': errors.code }"
                      v-model="details.code" placeholder="Enter code" :disabled="isLoading ? '' : isLoading" />

                    <div class="invalid-feedback" v-if="errors.code">
                      {{ errors.code[0] }}
                    </div>
                  </div>

                  <div class="form-group mt-6" v-if="errors.message">

                    <div class="alert alert-warning mb-4 p-0 alert-dismissible fade show" role="alert">

                      <div class="message"> {{ errors.message }}</div>
                      <a href="#" class="alert-button close" data-dismiss="alert" aria-label="Close"><i
                          class="fal fa-times"></i></a>
                    </div>
                  </div>

                  <button type="button" @click="sendVerifyCode" :disabled="isLoading ? '' : isLoading"
                    class="btn btn-primary btn-block font-weight-bold  font-size-lg rounded-sm">
                    <span v-if="!isLoading"> Confim</span>
                    <span v-if="isLoading">
                      <i class="fas fa-circle-notch fa-spin"></i> Verifying...</span>
                  </button>

                  <div
                    style="font-style: normal;font-weight: 400;font-size: 14px!important;text-align: center;color: #fff;margin-top: 20px;">
                    You want to request another?

                    <a :disabled="isResending ? '' : isResending" @click="verifyResend"
                      style="color: #9c9;font-weight: 500;cursor:pointer">
                      <span v-if="!isResending"> Resend</span>
                      <span v-if="isResending">
                        <i class="fas fa-circle-notch fa-spin"></i> Sending...
                      </span>
                    </a>.
                  </div>

                  <div class="form-group mt-6">

                    <MessageErrorFadeOut v-bind:message="error" v-bind:show="error?true:false" />
                    
                    <MessageSuccessFadeOut v-bind:message="success" v-bind:show="success?true:false" />


                  </div>
                </form>

                <div class="alert alert-info  alert-dismissible mb-4 p-0 fade show" role="alert">
                  <div class="alert-icon"><i class="fal fa-info-circle"></i></div>
                  <div class="message"> We emailed a code to your email address. Please enter the code to verify.
                  </div>
                  <a href="#" class="alert-button close" data-dismiss="alert" aria-label="Close"><i
                      class="fal fa-times"></i></a>
                </div>


              </div>



            </div>
          </div>
        </div>
      </div>

    </div>
    <br>
  </div>

</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut"
import MessageSuccessFadeOut from "../Common/Message/MessageSuccessFadeOut"
  export default {
    name: "ConfirmVerify",

    data() {
      return {
        isLoading:false,
        isResending:false,
        details: {
          code: null,
        },
        success: null,
        error: null,
      };
    },
components: {
      MessageErrorFadeOut,MessageSuccessFadeOut
    },
    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters(["errors", "isSuccess"])
    },

    mounted() {
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);
    },
    methods: {
      ...mapActions("auth", ["sendVerifyResendRequest", "sendVerifyCodeRequest"]),

      sendVerifyCode: function () {
        this.isLoading=true;
        this.sendVerifyCodeRequest(this.details).then(() => {
           this.isLoading=false;
          if (this.isSuccess) {

            if (this.user.hasBusiness) {
              this.$router.push({
                name: "Home"
              }).catch(() => {});
            } else {
              this.$router.push({
                name: "CreateBusiness"
              }).catch(() => {});
            }


          }
        });
      },
      verifyResend() {
        this.success = this.error = null;
        this.isResending=true;
        this.sendVerifyResendRequest()
          .then(() => {
            this.isResending=false;
            this.success =
              "A fresh verification code has been sent to your email address.";
          })
          .catch((error) => {
            this.isResending=false;
            this.error = "Error sending verification code.";
            console.log(error.response);
          });
      },
    },
  };
</script>