<template>
   <transition name="slide-fade">
        <div v-if="showAlert" class="
        alert alert-success
        mb-6
        alert-dismissible
        fade
        show
        d-flex
        align-items-center
        pr-3
      ">
            <div class="font-size-lg py-0 mr-6">{{message}}</div>
            <div class="ml-auto">
                <a href="#" class="alert-button py-0 font-size-h3 lh-1" data-dismiss="alert" aria-label="Close"><i
                        class="fal fa-times"></i></a>
            </div>
        </div>

    </transition>
</template>


<script>
export default {
  name: "MessageSuccessFadeOut",
  props: ["message", "show", "timeout"],
  data: function () {
    return {
      showAlert: this.show,
    };
  },
  mounted() {
    setTimeout(
      () => {
        this.showAlert = false;
      },
      this.timeout ? this.timeout : 10000
    ); // hide the message after 3 seconds
  },
};
</script>
<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>